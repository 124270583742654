import React from "react";

export function CalibrationIdentifierDisplay(props) {
  if (!props.value) {
    return null;
  }

  const formatted = props.value.toLocaleUpperCase();
  // const matched = props.value.match(/^(\D+)(\d+)$/);
  // const formatted = (!matched) ? props.value : `${matched[1].toLocaleUpperCase()}-${matched[2]}`;
  // const formatted = (!matched) ? props.value : matched[2];

  return (
    <span className={`identifier ${props.className ?? ""}`}>
      {formatted}
    </span>
  );
}
