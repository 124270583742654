// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --black: rgb(0, 0, 0);
  --white: rgb(255, 255, 255);
  --gray-50: rgb(10, 10, 12);
  --gray-100: rgb(18, 18, 22);
  --gray-150: rgb(32, 32, 35);
  --gray-150-40: rgba(32, 32, 35, .3);
  --gray-150-50: rgba(32, 32, 35, .5);
  --gray-175: rgb(50, 50, 52);
  --gray-200: rgb(68, 68, 70);
  --gray-300: rgb(84, 84, 86);
  --gray-400: rgb(108, 108, 112);
  --gray-500: rgb(142, 142, 147);
  --gray-600: rgb(174, 174, 178);
  --gray-700: rgb(188, 188, 192);
  --gray-800: rgb(216, 216, 220);
  --gray-850: rgb(235, 235, 238);
  --gray-900: rgb(250, 250, 252);
  --gray-100-a: rgb(18, 18, 22, 0.9);
  --gray-900-a: rgb(250, 250, 252, 0.9);
  --azure-800: rgb(200, 200, 240);
  --blue-100: rgb(34, 78, 121);
  --blue-200: rgb(41, 94, 145);
  --blue-300: rgb(48, 111, 171);
  --blue-400: rgb(55, 128, 197);
  --blue-500: rgb(65, 150, 233);
  --green-500: rgb(116, 171, 120);
  --green-600: rgb(145, 214, 149);
  --red-300: rgb(255, 64, 54);
  --red-600: rgb(201, 127, 123);
  --orange-100: rgb(101, 68, 19);
  --orange-200: rgb(178, 124, 42);
  --orange-300: rgb(230, 161, 58);
  --microsoft-azure: rgb(0, 128, 255);
}`, "",{"version":3,"sources":["webpack://./src/style/color_palette.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,2BAAA;EAEA,0BAAA;EACA,2BAAA;EACA,2BAAA;EACA,mCAAA;EACA,mCAAA;EACA,2BAAA;EACA,2BAAA;EACA,2BAAA;EACA,8BAAA;EACA,8BAAA;EACA,8BAAA;EACA,8BAAA;EACA,8BAAA;EACA,8BAAA;EACA,8BAAA;EAEA,kCAAA;EACA,qCAAA;EAEA,+BAAA;EASA,4BAAA;EACA,4BAAA;EACA,6BAAA;EACA,6BAAA;EACA,6BAAA;EAEA,+BAAA;EACA,+BAAA;EAEA,2BAAA;EACA,6BAAA;EAEA,8BAAA;EACA,+BAAA;EACA,+BAAA;EAEA,mCAAA;AAdF","sourcesContent":[":root {\n  --black: rgb(0, 0, 0);\n  --white: rgb(255, 255, 255);\n\n  --gray-50: rgb(10, 10, 12);\n  --gray-100: rgb(18, 18, 22);\n  --gray-150: rgb(32, 32, 35);\n  --gray-150-40: rgba(32, 32, 35, .3);\n  --gray-150-50: rgba(32, 32, 35, .5);\n  --gray-175: rgb(50, 50, 52);\n  --gray-200: rgb(68, 68, 70);\n  --gray-300: rgb(84, 84, 86);\n  --gray-400: rgb(108, 108, 112);\n  --gray-500: rgb(142, 142, 147);\n  --gray-600: rgb(174, 174, 178);\n  --gray-700: rgb(188, 188, 192);\n  --gray-800: rgb(216, 216, 220);\n  --gray-850: rgb(235, 235, 238);\n  --gray-900: rgb(250, 250, 252);\n\n  --gray-100-a: rgb(18, 18, 22, 0.9);\n  --gray-900-a: rgb(250, 250, 252, 0.9);\n\n  --azure-800: rgb(200, 200, 240);\n\n  // --blue-100: rgb(27, 32, 64);\n  // --blue-200: rgb(54, 64, 128);\n  // --blue-300: rgb(81, 96, 192);\n  // --blue-400: rgb(108, 128, 255);\n  // --blue-600: rgb(162, 192, 255);\n  // --blue-700: rgb(189, 224, 255);\n\n  --blue-100: rgb(34, 78, 121);\n  --blue-200: rgb(41, 94, 145);\n  --blue-300: rgb(48, 111, 171);\n  --blue-400: rgb(55, 128, 197);\n  --blue-500: rgb(65, 150, 233);\n\n  --green-500: rgb(116, 171, 120);\n  --green-600: rgb(145, 214, 149);\n\n  --red-300: rgb(255, 64, 54);\n  --red-600: rgb(201, 127, 123);\n\n  --orange-100: rgb(101, 68, 19);\n  --orange-200: rgb(178, 124, 42);\n  --orange-300: rgb(230, 161, 58);\n\n  --microsoft-azure: rgb(0, 128, 255);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
