
const divMod = (n, m) => [Math.floor(n / m), n % m];

export function formatDuration(duration) {
  if (!duration && duration !== 0) {
    return "n/a";
  }
  let hours, minutes, seconds;
  let milliseconds = duration;

  [hours, milliseconds] = divMod(milliseconds, 36e5);
  [minutes, milliseconds] = divMod(milliseconds, 6e4);
  [seconds, milliseconds] = divMod(milliseconds, 1e3);
  seconds = Math.round(10 * (seconds + (milliseconds / 1000))) / 10.0;

  let parts = [];
  if (hours !== 0) {
    parts.push(`${hours}h`);
  }
  if (parts.length > 0 || minutes !== 0) {
    parts.push(`${minutes}m`);
  }
  if (parts.length === 0 || seconds !== 0) {
    parts.push(`${seconds.toFixed(1)}s`);
  }
  return parts.join(" ");
}
