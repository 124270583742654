import _ from "lodash";
import React, {useEffect, useState} from "react";

import {formatDuration} from "../format/format_duration.js";

export function JobDurationDisplay(props) {
  const [end, setEnd] = useState(props.job.endedAt ?? props.job.exitedAt);

  useEffect(() => {
    let iv;
    if (props.job.endedAt ?? props.job.exitedAt) {
      clearInterval(iv);
      setEnd(props.job.endedAt ?? props.job.exitedAt);
    } else {
      iv = setInterval(() => setEnd(Date.now()), 100);
      return () => {
        clearInterval(iv);
      };
    }
  }, [props.job]);

  const duration = Math.max(0, end - (props.job.startedAt ?? end));
  let className = "job-duration-display clock";
  if (!props.job.endedAt && !props.job.exitedAt) {
    className += " executing";
  }
  return (
    <span className={className}>
      {formatDuration(duration)}
    </span>
  );
};
