// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pane.permissions-pane {
  padding: 1rem;
}
.pane.permissions-pane .permissions-table {
  text-align: left;
}
.pane.permissions-pane .permissions-title {
  margin: -1rem 0 0 -1rem;
}
.pane.permissions-pane th, .pane.permissions-pane td {
  padding: 0.3rem 1rem;
  white-space: nowrap;
}
.pane.permissions-pane tbody:not(:has(.focused)) tr {
  color: var(--color-elevated-text);
}
.pane.permissions-pane tbody:has(.focused) tr:not(.focused) {
  opacity: 0.5;
}
.pane.permissions-pane tr.focused td {
  background-color: var(--color-selected-canvas);
  color: var(--color-elevated-text);
}
.pane.permissions-pane tbody tr:nth-child(odd) td {
  background-color: var(--color-selected-canvas);
}`, "",{"version":3,"sources":["webpack://./src/style/panes/permissions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,uBAAA;AAEJ;AAAE;EACE,oBAAA;EACA,mBAAA;AAEJ;AACE;EACE,iCAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,8CAAA;EACA,iCAAA;AADJ;AAIE;EACE,8CAAA;AAFJ","sourcesContent":[".pane.permissions-pane {\n  padding: 1rem;\n  .permissions-table {\n    text-align: left;\n  }\n  .permissions-title {\n    margin: -1rem 0 0 -1rem;\n  }\n  th, td {\n    padding: 0.3rem 1rem;\n    white-space: nowrap;\n  }\n\n  tbody:not(:has(.focused)) tr {\n    color: var(--color-elevated-text);\n  }\n\n  tbody:has(.focused) tr:not(.focused) {\n    opacity: 0.5;\n  }\n\n  tr.focused td {\n    background-color: var(--color-selected-canvas);\n    color: var(--color-elevated-text);\n  }\n\n  tbody tr:nth-child(odd) td {\n    background-color: var(--color-selected-canvas);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
