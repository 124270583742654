import Aqumen from "@aqumen/sdk";
import {ButtonConfirmable} from "@minimum/utility";
import React, {useContext, useState} from "react";

import {ConnectionsContext} from "../context/connections_context.js";

export function ButtonSignal(props) {
  const {connections} = useContext(ConnectionsContext);
  const [signalled, setSignalled] = useState(false);

  const handleClick = async (event) => {
    await Aqumen.Job.signal(
      props.signal, props.job.session, props.job, connections[props.job.session.url]
    )
    setSignalled(true);
    if (props.onClick) {
      props.onClick(event);
    }
  }

  const className = `signal ${(signalled) ? "signalled" : ""} ${props.className ?? ""}`;

  return (
    <ButtonConfirmable {...props} className={className} onClick={handleClick}>
      {props.children}
    </ButtonConfirmable>
  );
}
