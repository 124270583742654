import React from "react";

export function UserDisplay(props) {
  const user = props.value;
  if (!user) {
    return null;
  }

  const className = `user display vcard ${props.className ?? ""}`;

  return (
    <span className={className} title={`User ID ${user.id}`}>
      <span className="fn">{user.fullName}</span>
      {user.email && (
        <a className="email" href={"mailto:" + user.email}>{user.email}</a>
      )}
    </span>
  );
}
