import Aqumen from "@aqumen/sdk";
import {Decimal} from "decimal.js";
import React from "react";

function isNumeric(str) {
  if (typeof str !== "string") {
    return false;
  }

  return /^-?[\d,.]+$/.test(str);
}

export function PercentDisplay(props) {
  let formatted;

  if (isNumeric(props.value)) {
    const {scale = 0} = props;
    const percent = new Decimal(props.value).plus(-1).times(-100);
    formatted = `${percent.toFixed(scale)} %`;
  } else {
    formatted = "-";
  }

  return (
    <span className={`epg ${props.className ?? ""}`}>
      {formatted}
    </span>
  );
}
