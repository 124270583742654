import _ from "lodash";
import React from "react";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

export function JobControlServerDisplay(props) {
  const job = props.job;
  const controlServer = props.job.controlServer;
  if (!controlServer) {
    return null;
  }

  const selectActiveReservations = createSelector(
    flatSelector("reservations"), rs => rs.filter(r => {
      return r.active && r.controlServer?.id === controlServer.id;
    })
  );
  const selectConflictingReservation = createSelector(
    selectActiveReservations, ar => ar.find(r => r.recipient?.id !== job.createdBy.id)
  );
  const conflictingReservation = useSelector(selectConflictingReservation);

  let className = "job-control-server-display";
  className += (controlServer.availableAt) ? " available" : " unavailable";
  className += (controlServer.lockedAt) ? " locked" : " unlocked";
  if (conflictingReservation) {
    className += " reservation-conflict";
  }

  return (
    <div className={className}>
      <span className="data-label">
        {intl.formatMessage({id: "jobs.job.controlServer.label"})}
      </span>
      <span className="data-value">
        {job.controlServer.identifier}
      </span>
    </div>
  );
}
