import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

import {jobStatusMessage} from "./job_status_message.js";
import {jobLastStatusTimestamp} from "./job_last_status_timestamp.js";
import {JobTimestampDisplay} from "./job_timestamp_display.jsx";


export function JobStatusDisplay(props) {
  if (!props.job) {
    return null;
  }

  const intl = useIntl();

  const status = jobStatusMessage(intl, props.job);
  const className = `job-status-detail-display ${_.kebabCase(props.job.status)} ${props.className ?? ""}`;

  if (props.job.exitedAt) {
    return (
      <div className={className}>
        <span className="data-label">{status}:</span>
        <JobTimestampDisplay className="job-last-status-change" label={status} value={jobLastStatusTimestamp(props.job)}/>
      </div>
    );
  }

  return (
    <div className={className}>
      <span className="data-label">{intl.formatMessage({id: "jobs.job.status.status.label"})}</span>
      <span className="jobs-label">{status}…</span>
    </div>
  );
}
