// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-boundary .error-message {
  margin-top: 1rem;
  color: var(--color-elevated-warn);
}

.error-boundary.pane {
  text-align: center;
  padding: 0 5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.error-boundary.pane .error-reset-button {
  margin-top: 1.5rem;
  width: 12rem;
}
.error-boundary.pane.confirming-reset .error-reset-button {
  color: var(--color-elevated-warn);
}

.navigation-menu-open .error-boundary.pane {
  width: calc(100% - 23rem);
}`, "",{"version":3,"sources":["webpack://./src/style/errors.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iCAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,WAAA;AACF;AACE;EACE,kBAAA;EACA,YAAA;AACJ;AAEE;EACE,iCAAA;AAAJ;;AAIA;EACE,yBAAA;AADF","sourcesContent":[".error-boundary .error-message {\n  margin-top: 1rem;\n  color: var(--color-elevated-warn);\n}\n\n.error-boundary.pane {\n  text-align: center;\n  padding: 0 5rem;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 100%;\n\n  .error-reset-button {\n    margin-top: 1.5rem;\n    width: 12rem;\n  }\n\n  &.confirming-reset .error-reset-button {\n    color: var(--color-elevated-warn);\n  }\n}\n\n.navigation-menu-open .error-boundary.pane {\n  width: calc(100% - (13rem + (5rem * 2)));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
