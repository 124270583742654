// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --border-width: 1px;
  --line-height: 2rem;
}

body {
  display: flow-root;
  line-height: var(--line-height);
  text-align: center;
}

.root-navigation {
  background-color: var(--color-elevated-canvas);
  text-align: center;
}

.main-content .logo.qci {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  width: 4rem;
}
.main-content .logo.qci:hover {
  opacity: 1;
}

.root-navigation.navigation-menu-open {
  display: grid;
  grid-template-columns: 13rem auto;
}
.root-navigation.navigation-menu-open .main-content .logo.qci {
  display: none;
}

.data-label {
  margin-right: 0.3rem;
}

.list {
  margin: 0;
  padding: 0;
}

.list .item:not(:first-child) {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: var(--border-elevated);
}

.pane {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  text-align: left;
}

.pane-title {
  border-bottom: var(--border-elevated);
  grid-column: 1/-1;
  height: calc(2rem + var(--border-width));
  line-height: calc(2rem + var(--border-width));
  margin: 0 0 0.5rem 0;
  padding: 0.5rem 1rem;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .pane {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/style/layout.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,+BAAA;EACA,kBAAA;AACF;;AAEA;EACE,8CAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EAAoB,WAAA;EAAa,aAAA;EACjC,WAAA;AAGF;AADE;EACE,UAAA;AAGJ;;AACA;EACE,aAAA;EACA,iCAAA;AAEF;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,SAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,kCAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;EACA,UAAA;EACA,gBAAA;AACF;;AAEA;EACE,qCAAA;EACA,iBAAA;EACA,wCAAA;EACA,6CAAA;EACA,oBAAA;EACA,oBAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,cAAA;EACF;AACF","sourcesContent":[":root {\n  --border-width: 1px;\n  --line-height: 2rem;\n}\n\nbody {\n  display: flow-root;\n  line-height: var(--line-height);\n  text-align: center;\n}\n\n.root-navigation {\n  background-color: var(--color-elevated-canvas);\n  text-align: center;\n}\n\n.main-content .logo.qci {\n  position: absolute; top: 0.7rem; right: 0.7rem;\n  width: 4rem;\n\n  &:hover {\n    opacity: 1;\n  }\n}\n\n.root-navigation.navigation-menu-open {\n  display: grid;\n  grid-template-columns: 13rem auto;\n\n  .main-content .logo.qci {\n    display: none;\n  }\n}\n\n.data-label {\n  margin-right: 0.3rem;\n}\n\n.list {\n  margin: 0;\n  padding: 0;\n}\n\n.list .item:not(:first-child) {\n  margin-top: 0.5rem;\n  padding-top: 0.5rem;\n  border-top: var(--border-elevated);\n}\n\n.pane {\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n  min-height: 100vh;\n  padding: 0;\n  text-align: left;\n}\n\n.pane-title {\n  border-bottom: var(--border-elevated);\n  grid-column: 1/-1;\n  height: calc(2rem + var(--border-width));\n  line-height: calc(2rem + var(--border-width));\n  margin: 0 0 0.5rem 0;\n  padding: 0.5rem 1rem;\n  text-align: left;\n}\n\n@media only screen and (max-width: 600px) {\n  .pane {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
