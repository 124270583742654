import React from "react";
import {useIntl} from "react-intl";

import {JobTimestampDisplay} from "./job_timestamp_display.jsx";
import {UserDisplay} from "../display/user_display.jsx";

export function JobReceivedDisplay(props) {
  const intl = useIntl();

  const job = props.job;

  const className = `job-creator-display ${props.className ?? ""}`;

  return (
    <div className={className}>
      {props.showJobCreator && (
        <UserDisplay className="job created-by" value={job.createdBy}/>
      )}
      {job.source && (
        <div className="job-source">
          <span className="data-label">
            {intl.formatMessage({id: "jobs.job.received.source.label"})}
          </span>
          {intl.formatMessage({id: "jobs.job.received.source." + job.source})}
        </div>
      )}
      <JobTimestampDisplay className="job-received-at" messageId="jobs.job.received.receivedAt" value={job.receivedAt}/>
    </div>
  );
}
