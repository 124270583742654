import _ from "lodash";
import {createSlice} from '@reduxjs/toolkit'


const slice = createSlice({
  name: "Preference",
  initialState: {},
  reducers: {
    "loadPreferences": (_state, action) => {
      const preferences = (action.payload) ? JSON.parse(action.payload) : {};
      Object.keys(ALL_FEATURES_AND_PREFERENCES).forEach(p => {
        if (!preferences.hasOwnProperty(p)) {
          preferences[p] = ALL_FEATURES_AND_PREFERENCES[p];
        }
      });
      return preferences;
    },
    "setPreferences": (_state, action) => action.payload
  }
});

export const ALL_FEATURES = {
  "features.qwa.wip.show": false,
  "features.qwa.theme.colorsOne": false
};

export const ALL_PREFERENCES = {
  "preferences.qwa.jobs.showCancelMany": false,
  "preferences.qwa.jobs.showCreator": false,
  "preferences.qwa.jobs.showHeader": false,
  "preferences.qwa.jobs.timestamps.seconds": false,
  "preferences.qwa.overrideColorScheme": "automatic"
};

export const TOGGLE_PREFERENCES = {
  "preferences.qwa.jobs.showHeader": false,
  "preferences.qwa.jobs.showCreator": false,
  "preferences.qwa.jobs.showCancelMany": false,
  "preferences.qwa.jobs.timestamps.seconds": false
};

export const ALL_FEATURES_AND_PREFERENCES = Object.assign(
  {}, ALL_FEATURES, ALL_PREFERENCES
);

export const {loadPreferences, setPreferences} = slice.actions;
export const {reducer} = slice;
