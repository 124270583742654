
import {accessControlEnvironment, accessControlEnvironmentKey} from "../environments.js";
import {authenticateEnvironment} from "../slice/environment_sessions_slice.js";
import {environmentKeys} from "../environments.js";
import {setAccessControlSession} from "../slice/access_control_session_slice.js";
import {setUserInterface} from "../slice/user_interface_slice.js";
import {loadPreferences} from "../slice/preferences_slice.js";
import {websocket} from "../websocket.js";

export async function processSession(dispatch, sessionPayload) {
  dispatch(setUserInterface({connected: false}));

  const session = Object.assign(
    {url: "https://" + accessControlEnvironment, key: accessControlEnvironmentKey},
    sessionPayload.data
  );
  dispatch(setAccessControlSession(session));
  if (session.user?.preferences) {
    dispatch(loadPreferences(session.user.preferences));
  }

  if (!session.environments || session.environments.length <= 0) {
    dispatch(setUserInterface({connected: true}));
    return {};
  }

  const connections = await Promise.all(
    session.environments.map(async (e) => {
      const host = e.host;
      await fetch(`https://${host}/api/v2/sessions/exchange`, {
        method: "POST",
        credentials: "include",
        headers: {
          "API-Key": environmentKeys[host],
          "Content-Type": "application/json"
        },
        body: JSON.stringify({token: e.exchangeToken})
      });
      const ws = await websocket(dispatch, {key: environmentKeys[host], url: "https://" + host});
      dispatch(authenticateEnvironment({key: environmentKeys[host], url: "https://" + host}));
      dispatch(setUserInterface({connected: true}));
      return {[host]: ws};
    })
  );


  return Object.assign(...connections);
}
