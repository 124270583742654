import React, {useEffect, useState} from "react";
import {Routes, Route} from "@minimum/router"
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {About} from "./about.jsx";
import {AccessControlPane} from "./access_control/access_control_pane.jsx";
import {AccountPane} from "./account/account_pane.jsx";
import {AccountPasskeysPane} from "./account_passkeys/account_passkeys_pane.jsx";
import {AccountReservationsPane} from "./account_reservations/account_reservations_pane.jsx";
import {ComingSoonPane} from "./coming_soon_pane.jsx";
import {JobsPane} from "./jobs/jobs_pane.jsx";
import LogoQci from "../assets/icons/logo_qci.svg";
import {NavigationErrorBoundary} from "./navigation_error_boundary.jsx";
import {NavigationMenu} from "./navigation/navigation_menu.jsx";
import {PermissionsPane} from "./permissions/permissions_pane.jsx";
import {RolesPane} from "./roles/roles_pane.jsx";
import {setUserInterface} from "./slice/user_interface_slice.js";
import {SystemsPane} from "./systems/systems_pane.jsx";
import {TokensPane} from "./tokens/tokens_pane.jsx";
import {UsersPane} from "./users/users_pane.jsx";
import {reloadAll} from "./requests/reload_all.js";


export function RootNavigation() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const aboutOpen = useSelector(s => s.userInterface.aboutOpen);
  const navigationMenuOpen = useSelector(s => s.userInterface.navigationMenuOpen);
  const sessions = useSelector((s) => s.environmentSessions);

  const [menuClosing, setMenuClosing] = useState(false);
  const [menuOpening, setMenuOpening] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => reloadAll(dispatch, sessions), []);

  const toggleMenuOpen = () => {
    setAnimate(true);
    if (navigationMenuOpen) {
      setTimeout(() => dispatch(setUserInterface({"navigationMenuOpen": !navigationMenuOpen})), 250);
      setTimeout(() => setMenuClosing(false), 250);
      setMenuClosing(true);
    } else {
      dispatch(setUserInterface({"navigationMenuOpen": !navigationMenuOpen}));
      setTimeout(() => setMenuOpening(false), 250);
      setMenuOpening(true);
    }
  };

  const handleProductClick = () => {
    if (!navigationMenuOpen) {
      dispatch(setUserInterface({"aboutOpen": !aboutOpen}))
    }
  };

  let className = "root-navigation";
  if (navigationMenuOpen) {
    className += " navigation-menu-open";
  } else {
    className += " navigation-menu-closed";
  }

  if (menuOpening) {
    className += " navigation-menu-opening";
  } else if (menuClosing) {
    className += " navigation-menu-closing";
  }

  if (animate) {
    className += " animate";
  }

  return (
    <div className={className}>
      {aboutOpen && (<About/>)}

      <NavigationMenu toggleMenuOpen={toggleMenuOpen}/>

      <div className="main-content">
        <button className="product" onClick={handleProductClick}>
          Aqumen
        </button>
        <a className="" href="https://quantumcircuits.com" target="blank">
          <LogoQci aria-label={intl.formatMessage({id: "aria.label.logo.qci"})}/>
        </a>
        <Routes errorBoundary={NavigationErrorBoundary}>
          <Route path="/access-control" element={<AccessControlPane/>}/>
          <Route path="/account" element={<AccountPane/>}/>
          <Route path="/administration" element={<ComingSoonPane label="administration"/>}/>
          <Route path="/billing" element={<ComingSoonPane label="billing"/>}/>
          <Route path="/control-servers" element={<SystemsPane/>}/>
          <Route path="/dashboard" element={<ComingSoonPane label="dashboard"/>}/>
          <Route path="/history" element={<ComingSoonPane label="history"/>}/>
          <Route path="/jobs" element={<JobsPane/>}/>
          <Route path="/organizations" element={<ComingSoonPane label="organizations" today={true}/>}/>
          <Route path="/passkeys" element={<AccountPasskeysPane label="passkeys"/>}/>
          <Route path="/permissions" element={<PermissionsPane/>}/>
          <Route path="/report-usage" element={<ComingSoonPane label="reportUsage"/>}/>
          <Route path="/reservations-administer" element={<ComingSoonPane label="reservationAdministration" today={true}/>}/>
          <Route path="/reservations-self" element={<AccountReservationsPane/>}/>
          <Route path="/reports" element={<ComingSoonPane label="reports"/>}/>
          <Route path="/roles" element={<RolesPane/>}/>
          <Route path="/tokens" element={<TokensPane/>}/>
          <Route path="/users" element={<UsersPane/>}/>
          <Route path="*" element={<JobsPane/>}/>
        </Routes>
      </div>

    </div>
  );
}
