import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {TimestampDisplay} from "../display/timestamp_display.jsx";

export function JobTimestampDisplay(props) {
  const intl = useIntl();
  const forceSeconds = useSelector(s => s.preferences?.["preferences.qwa.jobs.timestamps.seconds"]);
  const empty = (props.messageEmptyId) ? intl.formatMessage({id: props.messageEmptyId}) : "";

  return (
    <div className={"job-timestamp-display " + (props.className ?? "")}>
      <span className="data-label">
        {props.label ?? intl.formatMessage({id: props.messageId})}
      </span>
      <TimestampDisplay {...{forceSeconds, empty, value: props.value}}/>
    </div>
  );
};
